import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IAdvert } from 'src/app/models/IAdvert';
import { ICoupon } from 'src/app/models/ICoupon';
import { ICouponSubscription } from 'src/app/models/ICouponSubscription';
import { IUser } from 'src/app/models/IUser';
import { AdvertService } from 'src/app/services/advert.service';
import { SacouponsSubscriptionService } from 'src/app/services/coupons/sacoupons-subscription.service';
import { SACouponsService } from 'src/app/services/coupons/sacoupons.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-advert-coupon',
  templateUrl: './advert-coupon.component.html',
  styleUrls: ['./advert-coupon.component.scss'],
})
export class AdvertCouponComponent implements OnInit {

  coupon: ICoupon;
  subscription: ICouponSubscription;

  constructor(
    private saCouponsSubscriptionService: SacouponsSubscriptionService,
    private saCouponService: SACouponsService,
    private userService: UserService,
    private advertService: AdvertService,
    public modalController: ModalController,
    public router: Router
  ) { }

  ngOnInit() {
    // get the advert
    this.advertService.getAdvert().subscribe((advert: IAdvert) => {
      // clear so it doesn't show again
      this.advertService.clearAdvert();
      // get the coupon
      this.saCouponService.getCoupon(advert.couponId, advert.retailerId).subscribe(response => {
        const getCouponResponse: any = response;
        console.log('GetCouponresponse', getCouponResponse);
        this.coupon = getCouponResponse.coupon;
      });
    });

    // check if user has a subscription
    this.userService.getUser().subscribe((user: IUser) => {
      this.saCouponsSubscriptionService.getSubscription(user.id).subscribe((response: ICouponSubscription) => {
        this.subscription = response;
        console.log('Subscription', this.subscription);
      });
    });
  }

  subscribe()
  {
    this.router.navigate(['/coupons-intro']);
    this.dismiss();
  }

  collect() {
    // open a basket for the user if subscribed
    this.saCouponService.startOpenBasket(this.subscription.memberId, this.coupon.retailerId).subscribe(resp => {});

    //console.log(this.coupon);
    this.router.navigate([`/coupons-products/retailer/${this.coupon.retailerId}/category/${this.coupon.categoryId}`], {
      fragment: this.coupon.id.toString()
    });
    this.dismiss();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }
}
